'use strict';

import {ILogService, IRootScopeService, IScope, ITimeoutService} from "angular";
import {AdminAddressbookResponse, DatabaseAdminPersonResponse} from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";
import {SortParams} from "../../../views/addressbook.view.component/addressbook.view.component";
import {PersonSimple} from "../../../../data/person.data";

require('./admin.addressbook.component.css');

export default class AdminAddressbookComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {}
    this.template = require('./admin.addressbook.component.html');
    this.controller = AdminAddressbookComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class AdminAddressbookComponentController {
  public $log: ILogService;
  public $scope: IScope;
  public $uibModal: any;
  public restService: RestService;
  public data: AdminAddressbookResponse;
  public isLoading: boolean = false;
  public $timeout: ITimeoutService;

  public params: SortParams = {
    currentPage: 0,
    pageSize: 20
  } as SortParams;

  constructor($log: ILogService, $scope: IScope, $timeout: ITimeoutService, $uibModal: any, restService: RestService, public $rootScope: IRootScopeService) {
    this.$log = $log;
    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.$scope = $scope;
    this.restService = restService;

    this.$rootScope.$on('delete.person', (event, person) => {
      this.params.currentPage = 0;
      this.pageChanged();
    })
  }


  /**
   * Loads the entries paginated
   * @returns
   */
  pageChanged() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.searchForPersonsInAdmin(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.pageSize, this.params.searchFilter)
        .then((result: AdminAddressbookResponse) => {
          this.data = result;
          this.params.totalPages = result.totalPages;
          this.params.totalElements = result.totalElements;
        }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
        this.$timeout(function () {
          document.getElementById('admin-search-persons').focus();
        });
        resolve();
      });
    });
  }

  openPerson(personResponse: DatabaseAdminPersonResponse) {
    this.isLoading = true;
    this.isLoading = false;
    this.$uibModal.open({
      template: require('../../../modals/addressbook/edit.person.modal/edit.person.modal.html'),
      controller: 'EditPersonModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        person: {personID: personResponse.personID} as PersonSimple,
        openedAsAdmin: true,
        isNew: false
      }
    });
  }
}
